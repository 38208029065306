import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchFavouriteItemsForSaleByUserId } from './favouriteItemsForSaleByUserIdSlice';

export const toggleFavouriteItemForSale = createAsyncThunk('itemForSale/toggleFavouriteItemForSale', async ({ userId, itemForSaleId }, { dispatch }) => {
  const response = await fetch(`/api/items-for-sale/favourite`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId, itemForSaleId }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Favourite status toggled successfull');
  }

  const data = await response.json();
  dispatch(fetchFavouriteItemsForSaleByUserId(userId));
  return data;
});

const toggleFavouriteItemForSaleSlice = createSlice({
  name: 'itemForSale',
  initialState: { status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(toggleFavouriteItemForSale.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(toggleFavouriteItemForSale.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(toggleFavouriteItemForSale.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default toggleFavouriteItemForSaleSlice.reducer;

