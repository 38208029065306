import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching ItemsForsSale
export const fetchFavouriteItemsForSaleByUserId = createAsyncThunk('favouriteItemsForSaleByUserId/fetchFavouriteItemsForSaleByUserId', async (userId) => {
  const response = await fetch(`/api/items-for-sale/favourite/user/${userId}`);
  const data = await response.json();
  return data;
});

const favouriteItemsForSaleByUserIdSlice = createSlice({
  name: 'favouriteItemsForSaleByUserId',
  initialState: { favouriteItemsForSaleByUserId: [], status: 'idle', loading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFavouriteItemsForSaleByUserId.pending, (state) => {
        state.status = 'loading';
        state.loading = true; // Set loading to true when the request is pending
      })
      .addCase(fetchFavouriteItemsForSaleByUserId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.favouriteItemsForSaleByUserId = action.payload;
        state.loading = false; // Set loading back to false when the request is fulfilled
      })
      .addCase(fetchFavouriteItemsForSaleByUserId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false; // Set loading back to false when the request is rejected
      });
  },
});

export default favouriteItemsForSaleByUserIdSlice.reducer;
