// /* global FB */
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { 
    login, 
    googleLogin, 
    // facebookLogin 
} from '../../redux/slices/authenticationSlice';
import { useGoogleLogin } from '@react-oauth/google';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [formData, setFormData] = useState({ usernameOrEmail: '', password: '' });

    // useEffect(() => {
    //     // Function to initialize Facebook SDK
    //     const initializeFacebookSDK = () => {
    //         if (window.FB) {
    //             window.FB.init({
    //                 appId: process.env.REACT_APP_FB_APP_ID,
    //                 cookie: true,
    //                 xfbml: true,
    //                 version: 'v12.0' // Ensure this version is correct
    //             });
    //             window.FB.AppEvents.logPageView();
    //         }
    //     };

    //     // Load the Facebook SDK script
    //     (function(d, s, id) {
    //         if (d.getElementById(id)) {
    //             initializeFacebookSDK();
    //             return;
    //         }
    //         const js = d.createElement(s);
    //         js.id = id;
    //         js.src = "https://connect.facebook.net/en_US/sdk.js";
    //         js.onload = initializeFacebookSDK;
    //         d.getElementsByTagName(s)[0].parentNode.insertBefore(js, d.getElementsByTagName(s)[0]);
    //     })(document, 'script', 'facebook-jssdk');
    // }, []);

    const handleGoogleSuccess = async (tokenResponse) => {
        try {
            const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', {
                headers: {
                    Authorization: `Bearer ${tokenResponse.access_token}`,
                },
            });
            const userInfo = await userInfoResponse.json();

            if (userInfo.error) {
                throw new Error(userInfo.error.message);
            }
            await dispatch(googleLogin(userInfo));
            navigate('/'); // Redirect to main page after successful login
        } catch (error) {
            console.error('Google login failed', error);
            setErrorMessage('Google login failed');
        }
    };

    const handleGoogleFailure = (error) => {
        console.error('Login Failure:', error);
        setErrorMessage('Google login failed');
    };

    // const handleFacebookLogin = () => {
    //     if (window.FB) {
    //         window.FB.login((response) => {
    //             if (response.authResponse) {
    //                 const { accessToken } = response.authResponse;
    //                 dispatch(facebookLogin({ accessToken }))
    //                     .then(() => {
    //                         navigate('/'); // Redirect to main page after successful login
    //                     })
    //                     .catch((error) => {
    //                         console.error('Facebook login failed', error);
    //                         setErrorMessage('Facebook login failed');
    //                     });
    //             } else {
    //                 console.error('User cancelled login or did not fully authorize.');
    //                 setErrorMessage('Facebook login failed');
    //             }
    //         }, { scope: 'email' });
    //     } else {
    //         console.error('Facebook SDK not loaded.');
    //         setErrorMessage('Facebook SDK not loaded.');
    //     }
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await dispatch(login(formData));
            if (response.error && response.error.message) {
                setErrorMessage(response.error.message);
            } else {
                const userData = JSON.parse(localStorage.getItem('userData'));
                if (userData.user.school_id === null) {
                    const sellRedirectRoute = localStorage.getItem('redirectRoute');
                    navigate(sellRedirectRoute || '/');
                } else if (userData.user.school_id !== null && userData.user.email_verified === false) {
                    navigate('/school-email-registration');
                } else {
                    navigate('/school-dashboard');
                }              
            }
        } catch (error) {
            console.error(error);
            setErrorMessage('Login failed');
        }
    };

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: handleGoogleSuccess,
        onError: handleGoogleFailure,
    });

    return (
        <div className="container-fluid">
            <div className="row mt-5 justify-content-center">
                <div className="col-lg-4">
                    {errorMessage && <div className="text-danger">{errorMessage}</div>}
                    <h2 className="text-center">Login</h2>
                    <form onSubmit={handleSubmit} className="mb-4">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="usernameOrEmail">Username or Email</label>
                            <input
                                className="form-control form-control-lg"
                                type="usernameOrEmail"
                                id="usernameOrEmail"
                                name="usernameOrEmail"
                                autoComplete="usernameOrEmail"
                                value={formData.usernameOrEmail}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="password">Password</label>
                            <input
                                className="form-control form-control-lg"
                                type="password"
                                id="password"
                                name="password"
                                autoComplete="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary btn-lg w-100">Login</button>
                    </form>
                    <div className="text-center mb-3">
                        <Link to="/password-reset-request">Forgot password?</Link>
                    </div>
                    <div className="text-center mb-3">
                        Don't have an account? <Link to="/registration">Register</Link>
                    </div>
                    <hr />
                    <div className="text-center">
                        <button onClick={() => handleGoogleLogin()} className="btn btn-outline-primary btn-lg w-100 mb-3">
                            <FontAwesomeIcon icon="fa-brands fa-google" /> Login with Google
                        </button>
                        {/* <button onClick={handleFacebookLogin} className="btn btn-outline-dark w-100">
                            <FontAwesomeIcon icon="fa-brands fa-facebook" /> Login with Facebook
                        </button> */}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Login;
