import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for update ItemForSale
export const updateItemForSale = createAsyncThunk('itemForSale/updateItemForSale', async ({ formDataWithImages, itemForSaleId }, { rejectWithValue }) => {
  let response;
  try {
    response = await fetch(`/api/items-for-sale/${itemForSaleId}`, {
      method: 'PUT',
      body: formDataWithImages,
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Network response was not ok:', errorData);
        // Reject with error data
      return rejectWithValue(errorData.error || 'ItemForSale creation failed');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in createItemForSale thunk', error);
    return rejectWithValue(error.message || 'An error occurred during the API call');
  }
});

const updateItemForSaleSlice = createSlice({
  name: 'itemForSale',
  initialState: { itemForSale: null, status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateItemForSale.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateItemForSale.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.itemForSale = action.payload;
      })
      .addCase(updateItemForSale.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default updateItemForSaleSlice.reducer;

