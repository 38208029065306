import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createItemForSale } from '../../redux/slices/createItemForSaleSlice';
import { fetchItems } from '../../redux/slices/itemsSlice';
import SearchSchool from '../searchSchool/SearchSchool';
import calculatePrice from '../../utils/calculatePrice';
import { useDropzone } from 'react-dropzone';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const CreateItemForSale = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const createItemForSaleStatus = useSelector((state) => state.createItemForSale.status);
    const items = useSelector((state) => state.items.items);
    const filteredSchools = useSelector((state) => state.filterSchools.filteredSchools);

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [schoolIdMap, setSchoolIdMap] = useState({});
    const [school, setSchool] = useState('');
    const [schoolTown, setSchoolTown] = useState('');
    const [files, setFiles] = useState([]);
    const [formData, setFormData] = useState({
        school: '',
        item: '',
        size: '',
        gender: '',
        phase: '',
        season: '',
        is_p_e_item: '',
        description: '',
        initialPrice: '',
        donate: false,
        donationPercentage: 0,
    });

    const priceEarningDonation = calculatePrice(formData.initialPrice, formData.donationPercentage);
    const finalPrice = priceEarningDonation.roundedFinalPrice;

    useEffect(() => {
        dispatch(fetchItems());
    }, [dispatch]); 

    useEffect(() => {
        // Update the schoolIdMap when filteredSchools change
        const newSchoolIdMap = {};
        filteredSchools.forEach(school => {
            newSchoolIdMap[school.name] = school.id;
        });
        setSchoolIdMap(newSchoolIdMap);
    }, [filteredSchools]);
    
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        // If the checkbox is unchecked, set donationPercentage to 0 or blank
        if (name === 'donate') {
            setFormData((prevData) => ({ ...prevData, [name]: checked, donationPercentage: checked ? '25' : '0' }));
        } else {
            setFormData((prevData) => ({ ...prevData, [name]: newValue }));
        }
    };

    const handleSchoolChange = (value) => {
        const schoolName = value.split(',')[0];
        const schoolId = schoolIdMap[schoolName];
        setSchool(schoolName);
        setSchoolTown(value.split(',')[1]);
        setFormData((prevData) => ({ ...prevData, school: schoolId }));
    };

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*',
        maxFiles: 5
    });

    const handleDropzoneRemoveFile = (event, fileName) => {
        event.stopPropagation(); // Prevent the click event from bubbling up to the dropzone
        setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
    };

    const handleCloseConfirmationModal = () => setShowConfirmation(false);
    const handleShowConfirmationModal = () => setShowConfirmation(true);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate if the school is from the filtered list
        if (!formData.school) {
            setErrorMessage('Please select a valid school from the list.');
            return;
        }
        // Clear the error message and proceed with form submission
        setErrorMessage('');

        if (files.length === 0) {
            console.error('Files not selected');
            return;
        }

        // Show confirmation modal before submitting
        handleShowConfirmationModal();
    };

    const handleConfirmSubmit = async () => {
        // Close the confirmation modal
        handleCloseConfirmationModal();

        // Create FormData object to send files along with other form data
        const formDataWithImages = new FormData();

        // Append form data fields
        Object.entries(formData).forEach(([key, value]) => {
            formDataWithImages.append(key, value);
        });

        // Append the calculated finalPrice to the form data
        formDataWithImages.append('finalPrice', finalPrice);

        // Append the selected files
        files.forEach((file, index) => {
            formDataWithImages.append(`images`, file); // append each file
        })

        // Dispatch action to create item for sale
        dispatch(createItemForSale(formDataWithImages));
        setTimeout(() => {
            navigate('/');
        }, 1000); // Wait for 1 second before navigating to ensure fetchItems completes
    };

    const tooltip = (
        <Tooltip  className="custom-tooltip" id="tooltip">
            You can type part of the school's name along with the town or postcode, e.g., 'jam twi' for St James in Twickenham.
        </Tooltip>
    );

    return (
        <div className="container-fluid">
            <div className="container py-5">
                <div className="row mt-5 justify-content-center">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-11 pe-0">
                                <h2 className="text-center mb-lg-5">Sell your school uniform item</h2>
                            </div>
                        </div>
                        {errorMessage && (
                            <div className="alert alert-danger">{errorMessage}</div>
                        )}
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-11 pe-0 mb-3">
                                    <div className="form-floating">
                                        <SearchSchool onSchoolChange={handleSchoolChange} />
                                    </div>  
                                </div>
                                <div className="col-1 ps-2">
                                    <OverlayTrigger placement="top" overlay={tooltip}>
                                        <FontAwesomeIcon className="fs-5 text-primary" icon="fa-solid fa-circle-info" />
                                    </OverlayTrigger>
                                </div>   
                                <div className="col-11 pe-0 mb-3">
                                    <div className="form-floating">
                                        <select
                                            className="form-select text-capitalize"
                                            id="item"
                                            name="item"
                                            value={formData.item}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value=""></option>
                                            {items.map((item) => (
                                                <option key={item.id} value={item.id}>{item.item}</option>
                                            ))}
                                        </select>
                                        <label htmlFor="item">Select the item</label>
                                    </div>
                                </div>
                                <div className="col-11 pe-0 mb-3">
                                    <div className="form-floating">
                                        <input
                                            className="form-control"
                                            id="size"
                                            name="size"
                                            value={formData.size}
                                            onChange={handleChange}
                                        >
                                        </input>
                                        <label htmlFor="description">Size</label>
                                    </div>
                                </div>
                                <div className="col-11 pe-0 mb-3">
                                    <div className="form-floating">
                                        <textarea
                                            className="form-control"
                                            id="description"
                                            name="description"
                                            autoComplete="description"
                                            rows="10"
                                            value={formData.description}
                                            onChange={handleChange}
                                            required
                                            style={{height: '200px'}}
                                        >
                                        </textarea>
                                        <label htmlFor="description">Description</label>
                                    </div>
                                </div>
                                <div className="col-11 pe-0 mb-3">
                                    <div className="form-floating">
                                        <input
                                            className="form-control"
                                            type="number"
                                            min="0.00"
                                            max="10000.00"
                                            step="0.01"
                                            id="initialPrice"
                                            name="initialPrice"
                                            autoComplete="price"
                                            value={formData.initialPrice}
                                            onChange={handleChange}
                                            required
                                        />
                                        <label htmlFor="initialPrice">Price</label>
                                    </div>
                                </div>
                                {school &&
                                    <div className="col-11 pe-0 mb-3">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="donate"
                                                name="donate"
                                                checked={formData.donate}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="donate">
                                                Would you like to <strong>donate</strong> a portion or the full amount to <strong>{school}</strong> in <strong>{schoolTown}</strong>?
                                            </label>
                                        </div>
                                    </div>
                                }
                                {formData.donate && (
                                    <div className="col-11 pe-0 mb-5">
                                        <label className="mb-5" htmlFor="donationPercentage">
                                            What percentage of the price would you like to donate?
                                        </label>
                                        <div className="slider-wrapper">  
                                            <input
                                                className="form-range"
                                                type="range"
                                                min="25"
                                                max="100"
                                                step="25"
                                                id="donationPercentage"
                                                name="donationPercentage"
                                                value={formData.donationPercentage}
                                                onChange={handleChange}
                                                required={formData.donate}
                                            />
                                            <span
                                                className="slider-value"
                                                style={{
                                                    left: `calc(${((formData.donationPercentage - 25) / 75) * 100}% - 10px)`,
                                                }}
                                            >
                                                {formData.donationPercentage}%
                                            </span>
                                            <div className="graduation-marks mx-auto">
                                                <span className="vr"/>
                                                <span className="vr"/>
                                                <span className="vr"/>
                                                <span className="vr"/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="col-11 pe-0 mb-3">
                                    <div {...getRootProps({ className: 'dropzone' })} className="dropzone border-secondary-subtle rounded-2">
                                        <input {...getInputProps()} />
                                        <p className="small text-secondary">Drag 'n' drop max of 5 images here, or click to select images</p>
                                        <div className="dropzone-file-list">
                                            {files?.map((file, index) => (
                                                <p key={index}>
                                                    {file.name}
                                                    <button 
                                                        type="button" 
                                                        className="dropzone-remove-file-button" 
                                                        onClick={(e) => handleDropzoneRemoveFile(e, file.name)}
                                                    >
                                                        &times;
                                                    </button>
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-11 pe-0">
                                    <button type="submit" className="btn btn-primary btn-lg w-100" disabled={createItemForSaleStatus === 'loading'}>
                                        {createItemForSaleStatus === 'loading' ? 'Creating ItemForSale...' : 'Submit'}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <Modal show={showConfirmation} onHide={handleCloseConfirmationModal}>
                    <Modal.Header closeButton>
                        <Modal.Title className="">
                            {school}
                            <p className="fs-6 mb-0">{schoolTown}</p>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5 className="text-capitalize">{items.find((item) => item.id === parseInt(formData.item, 10))?.item}</h5>
                        <p>{formData.description}</p>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td className="small text-muted text-uppercase">Initial price</td>
                                    <td>£{priceEarningDonation.initialPrice}</td>
                                </tr>
                                <tr>
                                    <td className="small text-muted text-uppercase">Estimated payment process fee</td>
                                    <td>£{priceEarningDonation.estimatedPaymentProcessFee}</td>
                                </tr>
                                <tr>
                                    <td className="small text-muted text-uppercase">Price listed</td>
                                    <td>£{priceEarningDonation.roundedFinalPrice}</td>
                                </tr>
                                <tr>
                                    <td className="small text-muted text-uppercase">Platform commission</td>
                                    <td>£{priceEarningDonation.platformCommission}</td>
                                </tr>
                                <tr>
                                    <td className="small text-muted text-uppercase">You will <strong>donate</strong> to the school</td>
                                    <td>£{priceEarningDonation.donation}</td>
                                </tr>
                                <tr>
                                    <td className="small text-muted text-uppercase">You will <strong>receive</strong></td>
                                    <td>£{priceEarningDonation.userEarning}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseConfirmationModal}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleConfirmSubmit}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default CreateItemForSale;