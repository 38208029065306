import React, { useState, useRef } from 'react';
import './ImageZoom.css'; // Custom CSS for styling

const ImageZoom = ({ src, zoomLevel = 2 }) => {
    const [isHovering, setIsHovering] = useState(false);
    const [lensPosition, setLensPosition] = useState({ x: 0, y: 0 });
    const [lensWidth, setLensWidth] = useState('');
    const [lensHeight, setLensHeight] = useState('');
    const [imageWidth, setImageWidth] = useState('');
    const [imageHeight, setImageHeight] = useState('');

    const imageRef = useRef(null);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const handleMouseMove = (e) => {
        // Calculate mouse position relative to the image
        const imageBounds = imageRef.current.getBoundingClientRect();
        setLensWidth(imageBounds.width / 2);
        setLensHeight(imageBounds.height / 2);
        setImageWidth(imageBounds.width);
        setImageHeight(imageBounds.height);
        let x = e.clientX - imageBounds.left - lensWidth / 2;
        let y = e.clientY - imageBounds.top - lensHeight / 2;
    
        // Constrain lens within the image bounds
        x = Math.max(0, Math.min(x, imageWidth - lensWidth));
        y = Math.max(0, Math.min(y, imageHeight - lensHeight));
    
        setLensPosition({ x, y });
    };

    return (
        <div className="image-zoom-container">
            {/* Main Image */}
            <img
                src={src}
                alt="Zoomable"
                id="mainImage" 
                className="main-image"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onMouseMove={handleMouseMove}
                ref={imageRef}
            />
            
            {/* Magnifier Lens */}
            {isHovering && (
                <div
                    className="lens"
                    style={{
                        left: lensPosition.x + 'px',
                        top: lensPosition.y + 'px',
                        width: lensWidth,
                        height: lensHeight,
                    }}
                />
            )}

            {/* Zoomed-in Image */}
            {isHovering && (
                <div
                className="zoomed-image"
                style={{
                    backgroundImage: `url(${src})`,
                    backgroundSize: `${zoomLevel * 100}%`,
                    backgroundPosition: `${-(lensPosition.x * zoomLevel)}px ${
                        -(lensPosition.y * zoomLevel)
                    }px`,
                    width: imageWidth,
                    height: imageHeight,
                }}
                />
            )}
        </div>
    );
};

export default ImageZoom;
