import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchSchoolEarnings } from '../../redux/slices/schoolEarningsSlice';
import { fetchUserById } from '../../redux/slices/userByIdSlice';
import { fetchTransferFunds } from '../../redux/slices/transferFundsSlice';
import { fetchConnectedAccount } from '../../redux/slices/connectedAccountSlice';
import { createAccountLink } from '../../redux/slices/createAccountLinkSlice';
import { fetchPayouts } from '../../redux/slices/payoutsSlice';

import { checkStripeConnectedAccountStatus } from '../../utils/stripe'
import getPayoutClassAndLabel from '../../utils/getPayoutClassAndLabel'

import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';

const SchoolDashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [schoolEarningToWithdraw, setSchoolEarningToWithdraw] = useState('0.00');
    const [withdrawnSchoolEarning, setWithdrawnSchoolEarning] = useState('0.00');
    const [showSuccessfulSchoolWithdrawal, setShowSuccessfulSchoolWithdrawal] = useState(false);
    const [showFailedSchoolWithdrawal, setShowFailedSchoolWithdrawal] = useState(false);
    const [connectedAccountStatus, setconnectedAccountStatus] = useState('loading');
    
    const userById = useSelector((state) => state.userById.user);
    const schoolEarnings = useSelector((state) => state.schoolEarnings.schoolEarnings);
    const connectedAccount = useSelector((state) => state.connectedAccount.connectedAccount);
   
    const accountLink = useSelector((state) => state.createAccountLink.accountLink);
    const payouts = useSelector((state) => state.payouts.payouts);

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData?.user?.id;
    const connectedAccountId = userById?.stripe_connected_account_id;

    useEffect(() => {
        dispatch(fetchUserById(userId));
        dispatch(fetchSchoolEarnings(userId));
        dispatch(fetchConnectedAccount());
        dispatch(fetchPayouts());
    }, [dispatch, userId]);

    useEffect(() => {
        if (connectedAccountId && connectedAccountStatus === 'enabled') {
            dispatch(createAccountLink(connectedAccountId));
        }
    }, [dispatch, connectedAccountId, connectedAccountStatus]);

    // Memoizing effective school earnings
    const effectiveSchoolEarnings = useMemo(() => (
        schoolEarnings?.[0]?.donations?.filter(schoolEarning => (
            schoolEarning.donation_amount > 0
        )) || []
    ), [schoolEarnings]);

    const calculateSchoolEarningToWithdraw = useCallback(() => {
        if (Array.isArray(effectiveSchoolEarnings)) {
            const totalSchoolEarningToWithdraw = effectiveSchoolEarnings.filter(earning => earning.fund_transferred_to_school === false)
                .reduce((accumulator, current) => accumulator + parseFloat(current.donation_amount), 0).toFixed(2);
            if (schoolEarningToWithdraw !== totalSchoolEarningToWithdraw) {
                setSchoolEarningToWithdraw(totalSchoolEarningToWithdraw);
            }
        } else {
            if (schoolEarningToWithdraw !== '0.00') {
                setSchoolEarningToWithdraw('0.00');
            }
        }
    }, [effectiveSchoolEarnings, schoolEarningToWithdraw]);

    const calculateWithdrawnSchoolEarning = useCallback(() => {
        if (Array.isArray(effectiveSchoolEarnings)) {
            const totalWithdrawnSchoolEarning = effectiveSchoolEarnings.filter(earning => earning.fund_transferred_to_school === true)
                .reduce((accumulator, current) => accumulator + parseFloat(current.donation_amount), 0).toFixed(2);
            if (withdrawnSchoolEarning !== totalWithdrawnSchoolEarning) {
                setWithdrawnSchoolEarning(totalWithdrawnSchoolEarning);
            }
        } else {
            if (withdrawnSchoolEarning !== '0.00') {
                setWithdrawnSchoolEarning('0.00');
            }
        }
    }, [effectiveSchoolEarnings, withdrawnSchoolEarning]);

    // Check if the earning was paid
    const itemsForSalePayoutStatus = new Map();
    payouts.forEach(payout => {
        const ids = payout.metadata?.items_for_sale_ids?.split(','); // Split comma-separated ids
        ids?.forEach(id => {
            itemsForSalePayoutStatus.set(Number(id), {
                status: payout.status,
                arrivalDate: payout.arrival_date
            });
        });
    });

    useEffect(() => {
        calculateSchoolEarningToWithdraw();
        calculateWithdrawnSchoolEarning();
    }, [effectiveSchoolEarnings]);

    const totalSchoolEarning = () => {
        if (Array.isArray(effectiveSchoolEarnings)) { 
            return effectiveSchoolEarnings.reduce((accumulator, current) => accumulator + parseFloat(current.donation_amount), 0).toFixed(2);
        } else {
            return '0.00';
        }
    };

    useEffect(() => {
        const fetchConnectedAccountStatus = async () => {
            const status = await checkStripeConnectedAccountStatus(connectedAccount);
            setconnectedAccountStatus(status); // Update state once status is fetched
        };
        fetchConnectedAccountStatus(); // Call the function when the component mounts
    }, [connectedAccountId, connectedAccount]); // Dependency array ensures the effect runs when `connectedAccountId` changes

    const handleWithdrawSchoolEarnings = async () => {    
        try {
            const amount = (schoolEarningToWithdraw * 100).toFixed();
            const itemsForSaleIds = effectiveSchoolEarnings.filter(earning => earning.fund_transferred_to_school === false).map(earning => earning.item_for_sale_id);

            // Wait for the user data to be updated
            const updatedUserById = await dispatch(fetchUserById(userId));
            const newConnectedAccountId = updatedUserById.payload?.stripe_connected_account_id;

            if (!newConnectedAccountId) {
                console.log('Failed to create or fetch connected account.');
                return;
            }

            if (connectedAccountStatus === 'restricted') {
                navigate('/onboarding/pre');
                return;
            }

            // If onboarding is complete, proceed with withdrawal
            const resultAction = await dispatch(fetchTransferFunds({ userId, connectedAccountId, amount, itemsForSaleIds }));

            if (fetchTransferFunds.fulfilled.match(resultAction)) {
                await setSchoolEarningToWithdraw('0.00');
                await setWithdrawnSchoolEarning(totalSchoolEarning());
                await setShowSuccessfulSchoolWithdrawal(true);
            } else {
                await setShowFailedSchoolWithdrawal(true);
                throw new Error(resultAction.error.message || 'Transfer funds failed');
            }
            
        } catch (error) {
            console.error('Error during withdrawal process:', error.message);
            await setShowFailedSchoolWithdrawal(true);
            // Provide user-friendly error handling here
        }
    };

    return (
        <div className="container">
            <h2 className="my-5">{schoolEarnings[0]?.school_name}</h2>
            {connectedAccountStatus === 'enabled' ? (
                <p className="alert alert-warning" role="alert">
                    This account requires verification. <a href={`${accountLink?.url}`}>Click here</a> to avoid withdrawal interruption.
                </p>
            ):(
                <p></p>
            )}
            <section>
                <table className="table mt-5">
                    <thead>
                        <tr>
                            <th scope="col">Total Donations Received</th>
                            <th scope="col">Total Withdrawn</th>
                            <th scope="col">To Withdraw</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>£{totalSchoolEarning()}</td>
                            <td>£{withdrawnSchoolEarning}</td>
                            <td>
                                <span className={`${schoolEarningToWithdraw === '0.00' ? '' : 'bg-success text-white p-1 fw-bold'}`}>£{schoolEarningToWithdraw}</span>
                            </td>
                            <td>
                                <button onClick={handleWithdrawSchoolEarnings} className={`${schoolEarningToWithdraw === '0.00' ? 'disabled' : ''} btn btn-outline-primary btn-sm`}>Withdraw</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                    <Modal 
                        show={showSuccessfulSchoolWithdrawal} 
                        onHide={() => setShowSuccessfulSchoolWithdrawal(false)}
                        dialogClassName="modal-dialog-centered" 
                    >
                        <Modal.Header closeButton>
                            <Modal.Title className="text-center w-100">Withdrawal successful!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="text-center">
                            Your withdrawal request has been received!<br/>
                            The funds will be transferred to your account shortly, 
                            typically within a few hours. You can track the status of your payout on your dashboard, 
                            where it will update to 'Successful' once the transfer is complete.
                        </Modal.Body>
                    </Modal>
                    <Modal 
                        show={showFailedSchoolWithdrawal} 
                        onHide={() => setShowFailedSchoolWithdrawal(false)}
                        dialogClassName="modal-dialog-centered" 
                    >
                        <Modal.Header closeButton>
                            <Modal.Title className="text-center w-100">Oops, Something went wrong!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="text-center">Please try again later.</Modal.Body>
                    </Modal>
            </section>
            <section>
                <table className="table mt-5">
                    <thead>
                        <tr>
                            <th scope="col">Donation Date</th>
                            <th scope="col">Donor</th>
                            <th scope="col">Donation Received</th>
                            <th scope="col">Transfer Date</th>
                            <th scope="col">Transfer Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {effectiveSchoolEarnings.map(earning =>{
                            const { status, arrivalDate } = itemsForSalePayoutStatus.get(earning.item_for_sale_id) || {};
                            const { className, label } = getPayoutClassAndLabel(status);

                            return (
                            <tr key={earning.item_for_sale_id}>
                                <td>{new Date(earning.created_at).toLocaleDateString('en-GB')}</td>
                                <td>{earning.donor}</td>
                                <td>£{earning.donation_amount}</td>
                                <td>{arrivalDate ? new Date(arrivalDate * 1000).toLocaleDateString('en-GB') : ''}</td>
                                <td className={className}>{label}</td>
                            </tr>
                        )})}
                    </tbody>
                </table>
            </section>
        </div>
    );
};

export default SchoolDashboard;
