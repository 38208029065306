import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchImagesByItemForSaleId } from './imagesByItemForSaleIdSlice';

// Create an async thunk for removing images
export const removeItemForSaleImage = createAsyncThunk('itemForSaleImage/removeItemForSaleImage', async ({ imageId, itemForSaleId }, { dispatch }) => {
    try {
      const response = await fetch(`/api/items-for-sale/image/${imageId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      const data = await response.json();
      // Fetch images after image removal
      dispatch(fetchImagesByItemForSaleId(itemForSaleId));
      return data; 
    } catch (error) {
      console.error('Error removing item for sale image:', error);
      throw error; // Propagate the error
    }
  }
);

const removeItemForSaleImageSlice = createSlice({
  name: 'itemForSaleImage',
  initialState: { status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(removeItemForSaleImage.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeItemForSaleImage.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(removeItemForSaleImage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default removeItemForSaleImageSlice.reducer;