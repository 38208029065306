import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchBasket } from '../../redux/slices/basketSlice';
import { removeFromBasket } from '../../redux/slices/removeFromBasketSlice';


function Basket() {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const basketItems = useSelector((state) => state.basket.basket);
    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData.user.id;

    useEffect(() => {
        dispatch(fetchBasket(userId)); 
    }, [dispatch,  userId]);

    const handleRemoveFromBasket = (basketItemId) => {
        dispatch(removeFromBasket({ userId, basketItemId }));
    };

    // Function to calculate total final_price
    const calculateTotalPrice = () => {
        let totalPrice = 0;
        if (Array.isArray(basketItems)) {
            basketItems.forEach(item => {
                totalPrice += parseFloat(item.final_price);
            });
        }
        return totalPrice.toFixed(2); // Assuming final_price is in string format
    };

    const handleCheckout = () => {
        // Redirect to the PaymentPage with the user ID and total final_price
        navigate(`/payment/?totalPrice=${calculateTotalPrice()}`);
      };

    return (
        <div className="container-fluid">
            <div className="container py-5">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="p-5 mb-sm-3 border">
                            <div className="d-flex justify-content-between align-items-center mb-5">
                                <h6 className="mb-0 text-muted">{basketItems.length ? basketItems.length : 0} Items</h6>
                            </div>

                            <hr className="my-4" />
                    
                            {basketItems.length > 0 ? (
                                <>
                                    {basketItems.map((basketItem) => (
                                        <div key={basketItem.id}>
                                            <div className="row mb-4 d-flex justify-content-between align-items-center">
                                                <div className="col-md-2 col-lg-2 col-xl-2">
                                                    <img src={`https://storage.googleapis.com/dropop/uploads/${basketItem.image}`} className="img-fluid" alt={basketItem.item_name} /> 
                                                </div>
                                                <div className="col-md-3 col-lg-3 col-xl-3">
                                                    <div className="fs-5 fw-medium">{basketItem.item_name}</div>
                                                    <h6 className="">{basketItem.size}</h6>
                                                    <p className="">{basketItem.school_name}</p>
                                                    {(basketItem.to_removal || basketItem.sold) &&<p className="text-danger">Item is no longer available</p>}
                                                </div>
                                                <div className="col-md-3 col-lg-2 col-xl-2 offset-lg-1">
                                                    <h6 className="mb-0">£ {basketItem.final_price}</h6>
                                                </div>
                                                <div className="col-md-1 col-lg-1 col-xl-1 text-end">
                                                    <button onClick={() => handleRemoveFromBasket(basketItem.id)} type="button" className="btn-close" aria-label="Close"></button>
                                                </div>
                                            </div>

                                            <hr className="my-4" />

                                            
                                        </div>
                                    ))}
                                    <div className="pt-3">
                                        <h6 className="mb-0"><Link to="/" className="text-body"><i className="fas fa-long-arrow-alt-left me-2"></i>Back to shop</Link></h6>
                                    </div>
                                </>
                            ):(
                                <div className="">
                                    Basket is empty 
                                </div>
                            )}
                        </div>
                    </div>                  
                    <div className="col-lg-4">
                        <div className="p-5 border">
                            <h4 className="fw-bold mb-5 mt-2 pt-1">Summary</h4>

                            <hr className="my-4" />

                            <div className="d-flex justify-content-between mb-4">
                                <h5 className="text-uppercase">{basketItems.length ? basketItems.length : 0} items</h5>
                            </div>

                            <hr className="my-4" />

                            <div className="d-flex justify-content-between mb-5">
                                <h5 className="text-uppercase"><span className="fs-6 text-uppercase pe-3">Total price</span>£{calculateTotalPrice()}</h5>
                            </div>

                            {basketItems.length > 0 ? (
                                <>
                                {basketItems.some(item => item.to_removal === true || item.sold === true) ? (
                                    <button
                                        type="button"
                                        className="disabled btn btn-primary btn-block btn-lg"
                                        data-mdb-ripple-color="dark"
                                        onClick={handleCheckout}
                                        disabled // Add disabled attribute when at least one item is marked for removal
                                    >
                                        Checkout
                                    </button>
                                    ) : (
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-block btn-lg"
                                        data-mdb-ripple-color="dark"
                                        onClick={handleCheckout}
                                    >
                                        Checkout
                                    </button>
                                    )}
                                </>
                            ):(
                                ''
                  
                              
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Basket;


