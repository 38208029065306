import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchItemForSaleById } from '../../redux/slices/itemForSaleByIdSlice';
import { fetchAddToBasket } from '../../redux/slices/addToBasketSlice';
import { fetchFavouriteItemsForSaleByUserId } from '../../redux/slices/favouriteItemsForSaleByUserIdSlice';
import { toggleFavouriteItemForSale } from '../../redux/slices/toggleFavouriteItemForSaleSlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import ImageZoom from '../imageZoom/ImageZoom';

import './ItemsForSale.css'


function ItemForSaleDetail() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { itemForSaleId } = useParams();

    const itemForSale = useSelector((state) => state.itemForSaleById.itemForSale);
    const favouriteItemsForSale = useSelector((state) => state.favouriteItemsForSaleByUserId.favouriteItemsForSaleByUserId);
    const isLoggedIn = useSelector(state => state.authentication.isLoggedIn);

    const [addedToBasketMessage, setAddedToBasketMessage] = useState('');
    const [itemAlreadyInBasketMessage, setItemAlreadyInBasketMessage] = useState('');
    const [mainImage, setMainImage] = useState('');

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData?.user?.id;
    const isUserSchool = userData !== null && userData?.user?.school_id !== null;

    useEffect(() => {
        dispatch(fetchItemForSaleById(itemForSaleId));
        if (userId) {
            dispatch(fetchFavouriteItemsForSaleByUserId(userId));
        };   
    }, [dispatch, userId, itemForSaleId]);

    useEffect(() => {
        if (itemForSale) {
            setMainImage(`https://storage.googleapis.com/dropop/uploads/${itemForSale.images[0]}`);
        }
    }, [itemForSale]);

    // Event handler for adding item to basket
    const handleAddToBasket = async () => {
        if (!isLoggedIn) {
            // Redirect to login page with current location as state
            navigate('/login');
            return;
        }

        // Dispatch the addToBasket action with the itemForSaleId
        // Check if the item is already in the basket
        const response = await dispatch(fetchAddToBasket({ userId, itemForSaleId }));
        
        if (response.payload.message === 'Item already in the basket') {
            setItemAlreadyInBasketMessage(response.payload.message);
            setTimeout(() => {
                setItemAlreadyInBasketMessage('');
            }, 3000);
        } else {
            setAddedToBasketMessage(`Item added to basket`);
            setTimeout(() => {
                setAddedToBasketMessage('');
            }, 3000);
        }
    };

    const handleThumbnailClick = (image) => {
        setMainImage(`https://storage.googleapis.com/dropop/uploads/${image}`);
    };

    if (!itemForSale) {
        return <div>Loading...</div>; // Or a loading spinner or some placeholder content
    };

    const handleToggleFavouriteItemForSale = (itemForSaleId) => {
        dispatch(toggleFavouriteItemForSale({ userId, itemForSaleId }));
    };

    const donationTooltip = (
        <Tooltip className="custom-tooltip" id="tooltip">
            Proceeds from this item go to the school fund!
        </Tooltip>
    );

    return (
        <div className="container-fluid">
            {addedToBasketMessage && <div className="alert alert-success mt-2">{addedToBasketMessage}</div>}
            {itemAlreadyInBasketMessage && <div className="alert alert-warning mt-2">{itemAlreadyInBasketMessage}</div>}
            <section className="py-1">
                <div className="container my-5">
                    <div className="row gx-4 gx-lg-5">
                        <div className="col-md-2"></div>
                        <div className="col-md-4">
                            <div className="text-lg-end" >

                                {/* Show image zoom only on large screen  */}
                                <div className="d-none d-lg-block">
                                    <ImageZoom src={mainImage} />
                                </div>
                                <div className="d-block d-lg-none">
                                    <img 
                                        id="mainImage" 
                                        src={mainImage} 
                                        className="main-image" 
                                        alt={itemForSale.item_name} 
                                    />
                                </div>
                                
                                <div className="thumbnails d-flex justify-content-center">
                                    {itemForSale.images.slice(0, 5).map((image, index) => (
                                        <img 
                                            key={index} 
                                            src={`https://storage.googleapis.com/dropop/uploads/${image}`} 
                                            alt={`Thumbnail ${index + 1}`} 
                                            className="thumbnail-square m-1" 
                                            onClick={() => handleThumbnailClick(image)} 
                                        />
                                    ))}
                                </div>
            
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fs-5 fw-medium mb-0">{itemForSale.school_name}</div>
                            <div className="fs-6 fw-light mt-0 mb-1">{itemForSale.school_town}</div>
                            <div className="fs-3 fw-bold mb-1">£{itemForSale.final_price}</div>
                            <div className="fs-5 fw-bolder text-capitalize">{itemForSale.item_name}</div>
                            <div className="d-flex">
                                <div className="fs-6 me-1">size</div>
                                <div className="fs-6 text-capitalize mb-1">{itemForSale.size}</div>
                            </div>
                            <p className="lead">{itemForSale.item_description}</p>
                            <div className="d-flex align-items-center"> 
                                {/* Favourite toggle button */}
                                {userId && !isUserSchool ? (
                                    <div 
                                        onClick={() => handleToggleFavouriteItemForSale(itemForSale.id)} 
                                        className="fs-5 favourite-toggler me-2"
                                        aria-label={`Mark as ${favouriteItemsForSale.some((favourite) => favourite.item_for_sale_id === itemForSale.id) ? 'unfavourite' : 'favourite'}`}
                                        title={`Mark as ${favouriteItemsForSale.some((favourite) => favourite.item_for_sale_id === itemForSale.id) ? 'unfavourite' : 'favourite'}`}
                                    >
                                        {favouriteItemsForSale.some((favourite) => favourite.item_for_sale_id ===  itemForSale.id) ? (
                                            <FontAwesomeIcon className="text-danger fa-heart" icon="fa-solid fa-heart" />
                                        ):(
                                            <FontAwesomeIcon className="fa-heart" icon="fa-regular fa-heart" />
                                        )}
                                    </div>
                                ) : (
                                    <Link 
                                        to="/login"
                                        className="favourite-toggler fs-5 link-primary link-underline-opacity-0 me-2"
                                        title="Mark as favourite"
                                    > 
                                        <FontAwesomeIcon className="fa-heart" icon="fa-regular fa-heart" />
                                    </Link>
                                )}
                                {/* Donation icon */}
                                {itemForSale.school_donation_rate > 0 && (
                                    <OverlayTrigger placement="top" overlay={donationTooltip}>
                                        <FontAwesomeIcon className="fs-5 text-primary me-2" icon="fa-solid fa-piggy-bank" /> 
                                    </OverlayTrigger>
                                )}  
                            </div>
                            <hr/>
                            <div className="d-flex">
                                <button 
                                    onClick={() => handleAddToBasket(itemForSaleId)} 
                                    className={`${isUserSchool ? 'disabled' : ''} btn btn-outline-primary flex-shrink-0`}
                                >
                                    Add to Basket
                                </button> 
                            </div>
                            {isUserSchool && 
                                <p className="text-danger">You need to be logged in with a personal account to proceed with the purchase.</p>
                            }
                        </div>
                    </div>
                </div>
            </section>    
        </div>
    );
}

export default ItemForSaleDetail;


