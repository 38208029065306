import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching image by item for sale id
export const fetchImagesByItemForSaleId = createAsyncThunk('images/fetchImagesByItemForSaleId', async (itemForSaleId) => {
  const response = await fetch(`/api/items-for-sale/${itemForSaleId}/images`);
  const data = await response.json();
  return data;
});

const imagesByItemForSaleIdSlice = createSlice({
  name: 'images',
  initialState: { images: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchImagesByItemForSaleId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchImagesByItemForSaleId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.images = action.payload;
      })
      .addCase(fetchImagesByItemForSaleId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default imagesByItemForSaleIdSlice.reducer;