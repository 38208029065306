import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './Navbar.css'

import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import FilterSchool from '../filterSchool/FilterSchool';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function Navbar({ 
    basketItemsCount, 
    searchQuery, 
    onSearchQueryChange, 
    favouriteItemsForSale, 
    showFavourites, 
    onToggleFavourites 
}) {
    const location = useLocation();

    const itemsForSale = useSelector((state) => state.itemsForSale.itemsForSale);
    const isLoggedIn = useSelector((state) => state.authentication.isLoggedIn);
    const user = JSON.parse(localStorage.getItem('userData'));
    const isUserSchool = user?.user.school_id !== null;

    const isMainPage = location.pathname === '/';
    const isLoginPage = location.pathname === '/login';


    // Filter items based on the search query and exclude sold or to_removal items
    const filteredItemsForSale = itemsForSale.filter((item) =>
        item.school_name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        !item.sold && 
        !item.to_removal
    );

    return (
        <nav className="navbar navbar-expand-lg bg-body fixed-top">
            <div className="container">
                <Link className="navbar-brand" to="/">
                    <img src={"/logo/dp-lg-01.svg"} alt="Dropop" width="150" height="auto" />
                </Link>
                <button 
                    className="navbar-toggler" 
                    type="button" 
                    data-bs-toggle="offcanvas" 
                    data-bs-target="#offcanvasNavbar" 
                    aria-controls="offcanvasNavbar"  
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div 
                    className="offcanvas offcanvas-end" 
                    tabIndex="-1" 
                    id="offcanvasNavbar"
                    aria-labelledby="offcanvasNavbarLabel"
                >
                    <div className="offcanvas-header">
                        <Link className="navbar-brand" to="/" >
                            <img src={"/logo/dp-lg-01.svg"} alt="Dropop" width="150" height="auto" data-bs-dismiss="offcanvas" />
                        </Link>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        {filteredItemsForSale.count}
                        
                        {isMainPage && filteredItemsForSale.length > 5 ? (
                            <div className="search-school mx-auto d-none d-lg-block">
                                <FilterSchool searchQuery={searchQuery} onSchoolChange={onSearchQueryChange} />
                            </div>
                        ):(
                            <ul className="navbar-nav me-auto"></ul>
                        )}
                        <ul className="navbar-nav ml-auto align-items-lg-center">  
                            <li className="nav-item d-block d-lg-none">
                                {isLoggedIn && <div className="nav-link fst-italic fw-medium">Hi, {user.user.username}</div>}
                                <hr className="custom-divider" />
                            </li>
                            {isLoggedIn && isMainPage && favouriteItemsForSale.length > 0 &&
                                    <li className="nav-item" data-bs-dismiss="offcanvas">
                                        <FontAwesomeIcon
                                            icon={showFavourites ? "fa-solid fa-heart" : "fa-regular fa-heart"}
                                            className="fs-5 nav-link"
                                            onClick={onToggleFavourites}
                                            title={showFavourites ? "Show All" : "Show Favourites Only"}
                                        />
                                    </li>
                                }
                            <li className="nav-item" data-bs-dismiss="offcanvas">
                                <Link to="/" className="nav-link" aria-current="page">HOME</Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav ml-auto">  
                            {!isUserSchool && isLoggedIn ? (
                                <>
                                <li className="nav-item" data-bs-dismiss="offcanvas">
                                    <Link to="/create-item-for-sale" className="nav-link" aria-current="page">SELL</Link>
                                </li>
                                
                                <li className="nav-item border-start ms-3 me-2 custom-divider h-100"></li>
                                <li><hr className="custom-divider" /></li>
                                
                                <li className="nav-item dropdown d-none d-lg-block">
                                    <button className="nav-link dropdown-toggle nav-link-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        MY ACCOUNT
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <div className="nav-link fst-italic fw-medium">Hi, {user.user.username}</div>
                                        </li>
                                        <li><hr className="custom-divider" /></li>
                                        <li className="nav-item" data-bs-dismiss="offcanvas">
                                            <Link to="/dashboard" className="nav-link" aria-current="page">DASHBOARD</Link>
                                        </li>
                                        <li><hr className="custom-divider" /></li>
                                        <li className="nav-item" data-bs-dismiss="offcanvas">
                                            <Link to="/logout" className="nav-link" aria-current="page">LOGOUT</Link>
                                        </li>  
                                    </ul>
                                </li>

                                <li className="nav-item d-none d-lg-block" data-bs-dismiss="offcanvas">
                                    <Link to="/basket" className="nav-link" aria-current="page">BASKET({basketItemsCount ? basketItemsCount : 0})</Link>
                                </li>

                                {/* Remove links from dropdown on small screens */}
                                <ul className="navbar-nav">
                                    <li className="nav-item d-lg-none" data-bs-dismiss="offcanvas">
                                        <Link to="/dashboard" className="nav-link" aria-current="page">DASHBOARD</Link>
                                    </li>
                                    <li className="nav-item d-lg-none" data-bs-dismiss="offcanvas">
                                        <Link to="/basket" className="nav-link" aria-current="page">BASKET({basketItemsCount ? basketItemsCount : 0})</Link>
                                    </li>
                                    <li><hr className="custom-divider" /></li>
                                    <li className="nav-item d-lg-none" data-bs-dismiss="offcanvas">
                                        <Link to="/logout" className="nav-link" aria-current="page">LOGOUT</Link>
                                    </li>  
                                </ul>
                                </>
                            ) : isUserSchool && isLoggedIn ? (
                                <>
                                <li className="nav-item border-start ms-3 me-2 custom-divider h-100"></li>
                                <li><hr className="custom-divider" /></li>
                                <li className="nav-item dropdown d-none d-lg-block">
                                    <button className="nav-link dropdown-toggle nav-link-button" data-bs-toggle="dropdown" aria-expanded="false">
                                        MY ACCOUNT
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <div className="nav-link fst-italic fw-medium">Hi, {user.user.username}</div>
                                        </li>
                                        <li><hr className="custom-divider" /></li>
                                        <li className="nav-item" data-bs-dismiss="offcanvas">
                                            <Link to="/school-dashboard" className="nav-link" aria-current="page">DASHBOARD</Link>
                                        </li>
                                        <li><hr className="custom-divider" /></li>
                                        <li className="nav-item" data-bs-dismiss="offcanvas">
                                            <Link to="/logout" className="nav-link" aria-current="page">LOGOUT</Link>
                                        </li>  
                                    </ul>
                                </li>
                                {/* Remove links from dropdown on small screens */}
                                <ul className="navbar-nav">
                                    <li className="nav-item d-lg-none" data-bs-dismiss="offcanvas">
                                        <Link to="/school-dashboard" className="nav-link" aria-current="page">DASHBOARD</Link>
                                    </li>
                                    <li><hr className="custom-divider" /></li>
                                    <li className="nav-item d-lg-none" data-bs-dismiss="offcanvas">
                                        <Link to="/logout" className="nav-link" aria-current="page">LOGOUT</Link>
                                    </li>  
                                </ul>
                                </>
                            ) : (

                                <>
                                {!isLoginPage ? (
                                    <li className="nav-item" data-bs-dismiss="offcanvas">
                                        <Link to="/login" className="nav-link" aria-current="page">LOGIN</Link>
                                    </li>
                                ):(
                                    <li className="nav-item" data-bs-dismiss="offcanvas">
                                        <Link to="/registration" className="nav-link" aria-current="page">REGISTER</Link>
                                    </li>
                                )}
                                </>
                                
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;


