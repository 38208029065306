import 'bootstrap/dist/css/bootstrap.min.css';
import './ItemsForSale.css'

import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFavouriteItemForSale } from '../../redux/slices/toggleFavouriteItemForSaleSlice';
import Hero from '../hero/Hero';
import FilterSchool from '../filterSchool/FilterSchool';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ItemsForSale({
    // Properties from App.js 
    searchQuery, 
    onSearchQueryChange,
    itemsForSale, 
    filteredItemsForSale, 
    favouriteItemsForSale, 
    displayedItemsForSale
}) {
    const dispatch = useDispatch();

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData?.user?.id;
    const isUserSchool = userData !== null && userData?.user?.school_id !== null;

    const isLoggedIn = useSelector(state => state.authentication.isLoggedIn);

    // Check if all items are sold or to_removal
    const allItemsSoldOrToRemoval = itemsForSale.every(item => item.sold || item.to_removal);

    const isPageBlank = () => {
        return filteredItemsForSale.length === 0 && allItemsSoldOrToRemoval;
    };
    
    const handleToggleFavouriteItemForSale = (itemForSaleId) => { 
        dispatch(toggleFavouriteItemForSale({ userId, itemForSaleId }));           
    };

    const handleLoginRedirection = (itemForSaleId) => {
        localStorage.setItem('redirectRoute', `/item-for-sale/${itemForSaleId}`);
    };

    return (
        <div className="container-fluid">
            {filteredItemsForSale.length > 5 && (
                <div className="d-lg-none search-box position-fixed w-100 bg-white pt-3 pb-2 px-4 start-50 translate-middle-x z-3">
                    <FilterSchool searchQuery={searchQuery} onSchoolChange={onSearchQueryChange} />
                </div>
            )}
            <div className="container pt-3">
                {!isLoggedIn && <Hero isPageBlank={isPageBlank()}/>}
                <section className="" id="items-for-sale">
                    <div className="mt-5">
                        {
                            allItemsSoldOrToRemoval && !searchQuery && 
                            <div className="text-center">
                                <p className="">Can't spot anything you fancy? Why not flip those old school threads for some cash instead?</p>
                                {isLoggedIn && <Link to='/create-item-for-sale' type="button" className="btn btn-outline-primary btn-lg px-4">Sell now</Link>}
                            </div>
                        }
                        {filteredItemsForSale.length === 0 && searchQuery ? (
                            <p className="text-center">Can't spot anything you fancy? Why not flip those old school threads for some cash instead?"</p>
                        ) : (
                            <div className="row gx-4 gx-lg-5 pt-3">
                                {displayedItemsForSale.map((itemForSale) => (        
                                    <div key={itemForSale.id} className={`${itemForSale.sold || itemForSale.to_removal ? 'd-none' : ''} col-xxl-3 col-xl-4 col-lg-5 col-md-4 col-sm mb-3`}>
                                        <Link 
                                            to={`/item-for-sale/${itemForSale.id}`} 
                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                            className=""
                                            onClick={!isLoggedIn ? () => handleLoginRedirection(itemForSale.id) : ''}
                                        >
                                            <div className="card border-0 rounded-0 pb-0">
                                                <img 
                                                    src={`https://storage.googleapis.com/dropop/uploads/${itemForSale.images[0]}`} 
                                                    className="card-img-top-3x4 rounded-0" 
                                                    alt={itemForSale.item_name} 
                                                />
                                                <div className="card-body px-0 py-1">
                                                    <div className="fs-7 fw-medium text-truncate">{itemForSale.school_name}</div>
                                                    <div className="fs-7 mt-0 pt-0">{itemForSale.school_town}</div>
                                                    <div className="fs-6 fw-medium">£{itemForSale.final_price}</div>
                                                    <div className="fs-6 fw-bold text-capitalize">{itemForSale.item_name}</div>
                                                    <div className="d-flex align-items-baseline">
                                                        <div className="fs-7 me-1">size</div>
                                                        <div className="fs-6 text-capitalize">{itemForSale.size}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                        <div className="d-flex align-items-center mb-2">
                                            {/* Favourite toggle button */}
                                            {isLoggedIn && !isUserSchool ? (
                                                <div 
                                                    onClick={() => handleToggleFavouriteItemForSale(itemForSale.id)} 
                                                    className="favourite-toggler me-2"
                                                    aria-label={`Mark as ${favouriteItemsForSale.some((favourite) => favourite.item_for_sale_id === itemForSale.id) ? 'unfavourite' : 'favourite'}`}
                                                    title={`Mark as ${favouriteItemsForSale.some((favourite) => favourite.item_for_sale_id === itemForSale.id) ? 'unfavourite' : 'favourite'}`}
                                                >
                                                    {favouriteItemsForSale.some((favourite) => favourite.item_for_sale_id ===  itemForSale.id) ? (
                                                        <FontAwesomeIcon className="text-danger fa-heart" icon="fa-solid fa-heart" />
                                                    ):(
                                                        <FontAwesomeIcon className="fa-heart" icon="fa-regular fa-heart" />
                                                    )}
                                                </div>
                                            ) : (
                                                <Link 
                                                    to="/login"
                                                    className="favourite-toggler link-primary link-underline-opacity-0 me-2"
                                                    title="Mark as favourite"
                                                > 
                                                    <FontAwesomeIcon className="fa-heart" icon="fa-regular fa-heart" />
                                                </Link>
                                            )} 
                                            {/* Donation icon */}
                                            {itemForSale.school_donation_rate > 0 && (
                                                <FontAwesomeIcon 
                                                    className="fs-6 text-primary me-2" 
                                                    icon="fa-solid fa-piggy-bank" 
                                                    title="Proceeds from this item go to the school fund!"
                                                />
                                            )} 
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )} 
                    </div>
                </section>
            </div>                   
        </div>
    );
}

export default ItemsForSale;


